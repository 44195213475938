<template>
    <div>
        <div class="form-group">
        <label for="perGangguanJiwa">Apakah Pasien Mengalami Gangguan Jiwa?<strong class="text-danger">*</strong></label>
        <div>
            <b-form-radio-group
                :options="isParent.Config.mr.yesNoOpt"
                v-model="isParent.row.aukprj_is_gangguan_jiwa"
            />
            <VValidate 
                name="Gangguan Jiwa" 
                v-model="isParent.row.aukprj_is_gangguan_jiwa" 
                :rules="{required: 1}"
            />
        </div>
        </div>
        <!-- Jika Pasien Gangguan Jiwa -->
        <div id="pasienGangguanJiwa" v-if="isParent.row.aukprj_is_gangguan_jiwa == 'Y'">
        
        <div class="card">
            <div class="card-header bg-info">
            <h5 class="card-title font-weight-semibold">Kajian Edmonson</h5>
            </div>
            <div class="card-body">
            <div class="form-group">
                <label for="riskEdmonson">
                Parameter Risiko
                </label>
                <table class="table table-bordered table-hover pain-scale-table">
                <thead>
                    <tr>
                    <th>Pengkajian</th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                    <th class="table-warning text-center">Umur</th>
                    <td v-for="(v,k) in (Config.mr.edUmur||[])" :key="k">
                        <b-form-radio v-model="isParent.row.aukprj_ed_umur" name="aukprj_ed_umur" :value="v.value">{{v.text}}</b-form-radio>
                    </td>
                    </tr>
                    <tr>
                    <th class="table-warning text-center">Status Mental</th>
                    <td v-for="(v,k) in (Config.mr.edStatusMental||[])" :key="k">
                        <b-form-radio v-model="isParent.row.aukprj_ed_status_mental" name="aukprj_ed_status_mental" :value="v.value">{{v.text}}</b-form-radio>
                    </td>
                    </tr>
                    <tr>
                    <th class="table-warning text-center">Pola BAB/BAK</th>
                    <td v-for="(v,k) in (Config.mr.edPolaBAB||[])" :key="k">
                        <b-form-radio v-model="isParent.row.aukprj_ed_pola_pikir" name="aukprj_ed_pola_pikir" :value="v.value">{{v.text}}</b-form-radio>
                    </td>
                    </tr>
                    <tr>
                    <th class="table-warning text-center">Pengobatan</th>
                    <td v-for="(v,k) in (Config.mr.edPengobatan||[])" :key="k">
                        <b-form-radio v-model="isParent.row.aukprj_ed_pengobatan" name="aukprj_ed_pengobatan" :value="v.value">{{v.text}}</b-form-radio>
                    </td>
                    </tr>
                    <tr>
                    <th class="table-warning text-center">Diagnosis</th>
                    <td v-for="(v,k) in (Config.mr.edDiagnosis||[])" :key="k">
                        <b-form-radio v-model="isParent.row.aukprj_ed_diagnosis" name="aukprj_ed_diagnosis" :value="v.value">{{v.text}}</b-form-radio>
                    </td>
                    </tr>
                    <tr>
                    <th class="table-warning text-center">Ambulasi/Keseimbangan</th>
                    <td v-for="(v,k) in (Config.mr.edAmbulasi||[])" :key="k">
                        <b-form-radio v-model="isParent.row.aukprj_ed_ambulasi" name="aukprj_ed_ambulasi" :value="v.value">{{v.text}}</b-form-radio>
                    </td>
                    </tr>
                    <tr>
                    <th class="table-warning text-center">Nutrisi</th>
                    <td v-for="(v,k) in (Config.mr.edNutrisi||[])" :key="k">
                        <b-form-radio v-model="isParent.row.aukprj_ed_nutrisi" name="aukprj_ed_nutrisi" :value="v.value">{{v.text}}</b-form-radio>
                    </td>
                    </tr>
                    <tr>
                    <th class="table-warning text-center">Riwayat Jatuh</th>
                    <td v-for="(v,k) in (Config.mr.edRiwayatjatuh||[])" :key="k">
                        <b-form-radio v-model="isParent.row.aukprj_ed_riwayat_jatuh" name="aukprj_ed_riwayat_jatuh" :value="v.value">{{v.text}}</b-form-radio>
                    </td>
                    </tr>
                </tbody>
                <tfoot>
                    <tr class="table-info">
                    <td colspan="5" class="text-info-700">
                        <span class="text-uppercase">Tingkat Risiko Edmonson: {{edTotal||0}}</span>
                        
                        <span v-if="edTotal < 90" class="border-left ml-2 pl-2 font-weight-semibold"> Tidak Berisiko</span>
                        <span v-else class="border-left ml-2 pl-2 font-weight-semibold">Berisiko Tinggi</span>
                        
                    </td>
                    <!-- Penilaian Skor Skala Nyeri FLACC
                    0 = Santai dan Nyaman
                    1-3 = Ketidaknyamanan Ringan
                    4-6 = Nyeri Sedang
                    7-10 = Ketidaknyamanan/Nyeri Parah
                    -->
                    </tr>
                </tfoot>
                </table>
                <!--
                <VValidate 
                    name="Edmonson Umur" 
                    v-model="isParent.row.aukprj_ed_umur" 
                    :rules="toValidate(isParent.mrValidation.aukprj_ed_umur)"
                    class="d-block"
                />

                
                <VValidate 
                    name="Edmonson Status Mental" 
                    v-model="isParent.row.aukprj_ed_status_mental" 
                    :rules="toValidate(isParent.mrValidation.aukprj_ed_status_mental)"
                    class="d-block"
                />
                
                <VValidate 
                    name="Edmonson Pola Pikir" 
                    v-model="isParent.row.aukprj_ed_pola_pikir" 
                    :rules="toValidate(isParent.mrValidation.aukprj_ed_pola_pikir)"
                    class="d-block"
                />
                
                <VValidate 
                    name="Edmonson Pengobatan" 
                    v-model="isParent.row.aukprj_ed_pengobatan" 
                    :rules="toValidate(isParent.mrValidation.aukprj_ed_pengobatan)"
                    class="d-block"
                />
                
                <VValidate 
                    name="Edmonson Diagnosis" 
                    v-model="isParent.row.aukprj_ed_diagnosis" 
                    :rules="toValidate(isParent.mrValidation.aukprj_ed_diagnosis)"
                    class="d-block"
                />

                <VValidate 
                    name="Edmonson Ambulasi" 
                    v-model="isParent.row.aukprj_ed_ambulasi" 
                    :rules="toValidate(isParent.mrValidation.aukprj_ed_ambulasi)"
                    class="d-block"
                />

                
                <VValidate 
                    name="Edmonson Nutrisi" 
                    v-model="isParent.row.aukprj_ed_nutrisi" 
                    :rules="toValidate(isParent.mrValidation.aukprj_ed_nutrisi)"
                    class="d-block"
                />
                
                <VValidate 
                    name="Edmonson Riwayat Jatuh" 
                    v-model="isParent.row.aukprj_ed_riwayat_jatuh" 
                    :rules="toValidate(isParent.mrValidation.aukprj_ed_riwayat_jatuh)"
                    class="d-block"
                />
                -->
            </div>
            </div>
        </div>
        
        </div>
        <div id="pasienTidakGangguanJiwa" v-if="isParent.row.aukprj_is_gangguan_jiwa == 'N'">
        
            <div class="row mb-3">
                <div class="col-md-6" v-if="(isParent.row.ap_usia == null && isParent.row.ap_dob == null)">
                    <v-select 
                        :options="mGangguanJiwa"
                        v-model="isParent.row.aukprj_resiko_type"
                        placeholder="Pilih Kajian Resiko Jatuh"
                        label="text"
                    :clearable="true" :reduce="v=>v.value"
                    >
                    </v-select>
                    
                    <VValidate 
                        name="Kajian Gangguan Jiwa" 
                        v-model="isParent.row.aukprj_resiko_type" 
                        :rules="{required : 1}"
                    />
                </div>
            </div>

            <div class="card" v-if="(isParent.row.ap_usia <= 18 && isParent.row.ap_usia != null) || isParent.row.aukprj_resiko_type == '1'">
                <div class="card-header bg-info">
                <h5 class="card-title font-weight-semibold">Kajian Humpty Dumpty</h5>
                </div>
                <div class="card-body">
                <div class="form-group">
                    <table class="table table-bordered table-hover pain-scale-table">
                    <thead>
                        <tr>
                        <th>Pengkajian</th>
                        <th>Nilai 1</th>
                        <th>Nilai 2</th>
                        <th>Nilai 3</th>
                        <th>Nilai 4</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                        <th class="table-warning text-center">Umur</th>
                        <td v-for="(v,k) in (Config.mr.hdUmur||[])" :key="k">
                            <b-form-radio v-model="isParent.row.aukprj_humpty_umur" name="aukprj_humpty_umur" :value="v.value">{{v.text}}</b-form-radio>
                        </td>
                        </tr>
                        <tr>
                        <th class="table-warning text-center">Jenis Kelamin</th>
                        <td v-for="(v,k) in (Config.mr.hdJK||[])" :key="k">
                            <b-form-radio v-model="isParent.row.aukprj_humpty_jenis_kelamin" name="aukprj_humpty_jenis_kelamin" :value="v.value">{{v.text}}</b-form-radio>
                        </td>
                        </tr>
                        <tr>
                        <th class="table-warning text-center">Diagnosis</th>
                        <td v-for="(v,k) in (Config.mr.hdDiagnosis||[])" :key="k">
                            <b-form-radio v-model="isParent.row.aukprj_humpty_diagnosis" name="aukprj_humpty_diagnosis" :value="v.value">{{v.text}}</b-form-radio>
                        </td>
                        </tr>
                        <tr>
                        <th class="table-warning text-center">Gangguan Kognitif</th>
                        <td v-for="(v,k) in (Config.mr.hdGangguanKognitif||[])" :key="k">
                            <b-form-radio v-model="isParent.row.aukprj_humpty_gangguan_kognitif" name="aukprj_humpty_gangguan_kognitif" :value="v.value">{{v.text}}</b-form-radio>
                        </td>
                        </tr>
                        <tr>
                        <th class="table-warning text-center">Faktor Lingkungan</th>
                        <td v-for="(v,k) in (Config.mr.hdFaktorLingkungan||[])" :key="k">
                            <b-form-radio v-model="isParent.row.aukprj_humpty_faktor_lingkungan" name="aukprj_humpty_faktor_lingkungan" :value="v.value">{{v.text}}</b-form-radio>
                        </td>
                        </tr>
                        <tr>
                        <th class="table-warning text-center">Respon Pembedahan</th>
                        <td v-for="(v,k) in (Config.mr.hdPembedahan||[])" :key="k">
                            <b-form-radio v-model="isParent.row.aukprj_humpty_respon_pembedahan" name="aukprj_humpty_respon_pembedahan" :value="v.value">{{v.text}}</b-form-radio>
                        </td>
                        </tr>
                        <tr>
                        <th class="table-warning text-center">Penggunaan Obat</th>
                        <td v-for="(v,k) in (Config.mr.hdPenggunaanObat||[])" :key="k">
                            <b-form-radio v-model="isParent.row.aukprj_humpty_respon_penggunaan_obat" name="aukprj_humpty_respon_penggunaan_obat" :value="v.value">{{v.text}}</b-form-radio>
                        </td>
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr class="table-info">
                        <td colspan="5" class="text-info-700">
                            <span class="text-uppercase">Tingkat Risiko Humpty Dumpty: {{hdTotal}}</span>
                            <span v-if="hdTotal < 7" class="border-left ml-2 pl-2 font-weight-semibold">Tidak Beresiko</span>
                            <span v-else-if="hdTotal >= 7 && hdTotal <= 11" class="border-left ml-2 pl-2 font-weight-semibold">Risiko Rendah</span>
                            <span v-else-if="hdTotal >= 12" class="border-left ml-2 pl-2 font-weight-semibold">Risiko Tinggi</span>
                        </td>
                        <!-- Penilaian Tingkat Risiko Humpty Dumpty
                        7 - 11 = Risiko Rendah
                        >= 12 = Risiko Tinggi
                        -->
                        </tr>
                    </tfoot>
                    </table>
                    <!--
                    <VValidate 
                        name="Humpty Dumpty Umur" 
                        v-model="isParent.row.aukprj_humpty_umur" 
                        :rules="toValidate(isParent.mrValidation.aukprj_humpty_umur)"
                        class="d-block"
                    />

                    
                    <VValidate 
                        name="Humpty Dumpty Jenis Kelamin" 
                        v-model="isParent.row.aukprj_humpty_jenis_kelamin" 
                        :rules="toValidate(isParent.mrValidation.aukprj_humpty_jenis_kelamin)"
                        class="d-block"
                    />
                    
                    <VValidate 
                        name="Humpty Dumpty Diagnosis" 
                        v-model="isParent.row.aukprj_humpty_diagnosis" 
                        :rules="toValidate(isParent.mrValidation.aukprj_humpty_diagnosis)"
                        class="d-block"
                    />
                    
                    <VValidate 
                        name="Humpty Dumpty Gangguan Kognitif" 
                        v-model="isParent.row.aukprj_humpty_gangguan_kognitif" 
                        :rules="toValidate(isParent.mrValidation.aukprj_humpty_gangguan_kognitif)"
                        class="d-block"
                    />
                    
                    <VValidate 
                        name="Humpty Dumpty Faktor Lingkungan" 
                        v-model="isParent.row.aukprj_humpty_faktor_lingkungan" 
                        :rules="toValidate(isParent.mrValidation.aukprj_humpty_faktor_lingkungan)"
                        class="d-block"
                    />

                    <VValidate 
                        name="Humpty Dumpty Respon Pembedahan" 
                        v-model="isParent.row.aukprj_humpty_respon_pembedahan" 
                        :rules="toValidate(isParent.mrValidation.aukprj_humpty_respon_pembedahan)"
                        class="d-block"
                    />
                    -->

                </div>
                </div>
            </div>
            
            <div class="card" v-else-if="(isParent.row.ap_usia > 18 && isParent.row.ap_usia <= 60) || isParent.row.aukprj_resiko_type == '2'">
                <div class="card-header bg-info">
                <h5 class="card-title font-weight-semibold">Kajian Morse Fall Scale</h5>
                </div>
                <div class="card-body">
                <div class="form-group">
                    <label for="kidPainScale">
                    Skala Nyeri
                    </label>
                    <table class="table table-bordered table-hover pain-scale-table">
                    <thead>
                        <tr>
                        <th>Pengkajian</th>
                        <th>Nilai 1</th>
                        <th>Nilai 2</th>
                        <th>Nilai 3</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                        <th class="table-warning text-center">Riwayat Jatuh dalam 3 Bulan Terakhir</th>
                        <td v-for="(v,k) in (Config.mr.snRiwayatJatuh||[])" :key="k">
                            <b-form-radio v-model="isParent.row.aukprj_morshe_riwayat_jatuh" name="aukprj_morshe_riwayat_jatuh" :value="v.value">{{v.text}}</b-form-radio>
                        </td>
                        </tr>
                        <tr>
                        <th class="table-warning text-center">Diagnosis Medis Sekunder > 1</th>
                        <td v-for="(v,k) in (Config.mr.snDiagnosa||[])" :key="k">
                            <b-form-radio v-model="isParent.row.aukprj_morshe_diagnosa" name="aukprj_morshe_diagnosa" :value="v.value">{{v.text}}</b-form-radio>
                        </td>
                        </tr>
                        <tr>
                        <th class="table-warning text-center">Alat Bantu Jalan</th>
                        <td v-for="(v,k) in (Config.mr.snAlatBantuJalan||[])" :key="k">
                            <b-form-radio v-model="isParent.row.aukprj_morshe_alat_bantu" name="aukprj_morshe_alat_bantu" :value="v.value">{{v.text}}</b-form-radio>
                        </td>
                        </tr>
                        <tr>
                        <th class="table-warning text-center">Pakai Infus</th>
                        <td v-for="(v,k) in (Config.mr.snPakaiInfus||[])" :key="k">
                            <b-form-radio v-model="isParent.row.aukprj_morshe_inpus" name="aukprj_morshe_inpus" :value="v.value">{{v.text}}</b-form-radio>
                        </td>
                        </tr>
                        <tr>
                        <th class="table-warning text-center">Cara Berjalan / Berpindah</th>
                        <td v-for="(v,k) in (Config.mr.snCaraJalan||[])" :key="k">
                            <b-form-radio v-model="isParent.row.aukprj_morshe_cara_jalan" name="aukprj_morshe_cara_jalan" :value="v.value">{{v.text}}</b-form-radio>
                        </td>
                        </tr>
                        <tr>
                        <th class="table-warning text-center">Status Mental</th>
                        <td v-for="(v,k) in (Config.mr.snStatusMental||[])" :key="k">
                            <b-form-radio v-model="isParent.row.aukprj_morshe_status_mental" name="aukprj_morshe_status_mental" :value="v.value">{{v.text}}</b-form-radio>
                        </td>
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr class="table-info">
                        <td colspan="5" class="text-info-700">
                            <span class="text-uppercase">Tingkat Risiko Morse Fall Scale: {{morsheTotal}}</span>
                            <span v-if="morsheTotal < 24" class="border-left ml-2 pl-2 font-weight-semibold">Risiko Rendah</span>
                            <span v-else-if="morsheTotal >= 25 && morsheTotal <= 44" class="border-left ml-2 pl-2 font-weight-semibold">Risiko Sedang</span>
                            <span v-else-if="morsheTotal > 45" class="border-left ml-2 pl-2 font-weight-semibold">Risiko Tinggi</span>

                        </td>
                        <!-- Penilaian Tingkat Risiko Humpty Dumpty
                        7 - 11 = Risiko Rendah
                        >= 12 = Risiko Tinggi
                        -->
                        </tr>
                    </tfoot>
                    </table>

                    <!--
                    <VValidate 
                        name="Morshe Riwayat Jatuh" 
                        v-model="isParent.row.aukprj_morshe_riwayat_jatuh" 
                        :rules="toValidate(isParent.mrValidation.aukprj_morshe_riwayat_jatuh)"
                        class="d-block"
                    />

                    
                    <VValidate 
                        name="Morshe Diagnosa" 
                        v-model="isParent.row.aukprj_morshe_diagnosa" 
                        :rules="toValidate(isParent.mrValidation.aukprj_morshe_diagnosa)"
                        class="d-block"
                    />
                    
                    <VValidate 
                        name="Morshe Alat Bantu" 
                        v-model="isParent.row.aukprj_morshe_alat_bantu" 
                        :rules="toValidate(isParent.mrValidation.aukprj_morshe_alat_bantu)"
                        class="d-block"
                    />
                    
                    <VValidate 
                        name="Morshe Inpus" 
                        v-model="isParent.row.aukprj_morshe_inpus" 
                        :rules="toValidate(isParent.mrValidation.aukprj_morshe_inpus)"
                        class="d-block"
                    />
                    
                    <VValidate 
                        name="Morshe Cara Jalan" 
                        v-model="isParent.row.aukprj_morshe_cara_jalan" 
                        :rules="toValidate(isParent.mrValidation.aukprj_morshe_cara_jalan)"
                        class="d-block"
                    />

                    <VValidate 
                        name="Morshe Status Mental" 
                        v-model="isParent.row.aukprj_morshe_status_mental" 
                        :rules="toValidate(isParent.mrValidation.aukprj_morshe_status_mental)"
                        class="d-block"
                    />
                    -->


                    <div class="row mt-2">
                    <div class="col-md-6">
                        <table class="table table-bordered table-striped table-hover table-sm patient-table">
                        <thead>
                            <tr>
                            <th>HASIL</th>
                            <th>TINDAKAN</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                            <td>Tidak Berisiko</td>
                            <td>Perawatan yang Baik</td>
                            </tr>
                            <tr>
                            <td>Risiko Rendah</td>
                            <td>Lakukan intervensi jatuh standar</td>
                            </tr>
                            <tr>
                            <td>Risiko Tinggi</td>
                            <td>Lakukan intervensi jatuh risiko tinggi</td>
                            </tr>
                        </tbody>
                        </table>
                    </div>
                    <!-- <div class="col-md-6">
                        <div class="form-group">
                        <label for="">Catatan Tindakan </label>
                        <textarea name="" id="" rows="4" class="form-control"></textarea>
                        </div>
                    </div> -->
                    </div>
                </div>
                </div>
            </div>

            <div class="card" v-else-if="isParent.row.ap_usia > 60 || isParent.row.aukprj_resiko_type == '3'">
                <div class="card-header bg-info">
                <h5 class="card-title font-weight-semibold">Kajian Skala Ontario Modified Stratify - Sydney Scoring</h5>
                </div>
                <div class="card-body">
                <div class="form-group">
                    <label for="kidPainScale">Skala Risiko</label>
                    <table class="table table-bordered">
                    <tbody>
                        <tr class="table-warning">
                        <th colspan="2"><span class="font-weight-semibold">Riwayat Jatuh</span></th>
                        </tr>
                        <tr>
                        <td>Apakah pasien datang ke rumah sakit karena jatuh?</td>
                        <td>
                            <b-form-radio-group
                                
                                :options="isParent.Config.mr.yesNoOptV2"
                                v-model="isParent.row.aukprj_riwayat_jatuh_is_jatuh"
                            />
                            <!--
                            <VValidate 
                                name="Pasien Jatuh" 
                                v-model="isParent.row.aukprj_riwayat_jatuh_is_jatuh" 
                                :rules="toValidate(isParent.mrValidation.aukprj_riwayat_jatuh_is_jatuh)"
                            />
                            -->
                        </td>
                        </tr>
                        <tr v-if="isParent.row.aukprj_riwayat_jatuh_is_jatuh == 'N'">
                        <td>Jika Tidak, apakah pasien mengalami jatuh dalam 2 bulan terakhir?</td>
                        <td>
                            <b-form-radio-group
                                
                                :options="isParent.Config.mr.yesNoOptV2"
                                v-model="isParent.row.aukprj_riwayat_jatuh_is_jatuh2bulan"
                            />
                            <!--
                            <VValidate 
                                name="Pasien Jatuh Dalam 2 Bulan" 
                                v-model="isParent.row.aukprj_riwayat_jatuh_is_jatuh2bulan" 
                                :rules="toValidate(isParent.mrValidation.aukprj_riwayat_jatuh_is_jatuh2bulan)"
                            />
                            -->
                        </td>
                        </tr>
                    </tbody>
                    <tbody>
                        <tr class="table-warning">
                        <th colspan="2"><span class="font-weight-semibold">Status Mental</span></th>
                        </tr>
                        <tr>
                        <td>Apakah pasien delirium? (tidak membuat keputusan, pola pikir tidak terorganisir, gangguan daya ingat)</td>
                        <td>
                            <b-form-radio-group
                                
                                :options="isParent.Config.mr.yesNoOptV2"
                                v-model="isParent.row.aukprj_status_mental_dellirium"
                            />
                            <!--
                            <VValidate 
                                name="Mental Dellirium" 
                                v-model="isParent.row.aukprj_status_mental_dellirium" 
                                :rules="toValidate(isParent.mrValidation.aukprj_status_mental_dellirium)"
                            />
                            -->
                        </td>
                        </tr>
                        <tr>
                        <td>Apakah pasien disorientasi? (salah menyebutkan waktu, tempat atau orang)</td>
                        <td>
                            <b-form-radio-group
                                
                                :options="isParent.Config.mr.yesNoOptV2"
                                v-model="isParent.row.aukprj_status_mental_disorientasi"
                            />
                            <!--
                            <VValidate 
                                name="Mental Disorientasi" 
                                v-model="isParent.row.aukprj_status_mental_disorientasi" 
                                :rules="toValidate(isParent.mrValidation.aukprj_status_mental_disorientasi)"
                            />
                            -->
                        </td>
                        </tr>
                        <tr>
                        <td>Apakah pasien mengalami agitasi? (ketakutan, gelisah dan cemas)</td>
                        <td>
                            
                            <b-form-radio-group
                                
                                :options="isParent.Config.mr.yesNoOptV2"
                                v-model="isParent.row.aukprj_status_mental_agitasi"
                            />
                            <!--
                            <VValidate 
                                name="Mental Agitasi" 
                                v-model="isParent.row.aukprj_status_mental_agitasi" 
                                :rules="toValidate(isParent.mrValidation.aukprj_status_mental_agitasi)"
                            />
                            -->
                        </td>
                        </tr>
                    </tbody>
                    <tbody>
                        <tr class="table-warning">
                        <th colspan="2"><span class="font-weight-semibold">Penglihatan</span></th>
                        </tr>
                        <tr>
                        <td>Apakah memakai kacamata?</td>
                        <td>
                            <b-form-radio-group
                                
                                :options="isParent.Config.mr.yesNoOptV2"
                                v-model="isParent.row.aukprj_penglihatan_kacamata"
                            />
                            <!--
                            <VValidate 
                                name="Penglihatan Kacamata" 
                                v-model="isParent.row.aukprj_penglihatan_kacamata" 
                                :rules="toValidate(isParent.mrValidation.aukprj_penglihatan_kacamata)"
                            />
                            -->
                        </td>
                        </tr>
                        <tr>
                        <td>Apakah pasien mengeluh penglihatan buram?</td>
                        <td>
                            <b-form-radio-group
                                
                                :options="isParent.Config.mr.yesNoOptV2"
                                v-model="isParent.row.aukprj_penglihatan_buram"
                            />
                            <!--
                            <VValidate 
                                name="Penglihatan Buram" 
                                v-model="isParent.row.aukprj_penglihatan_buram" 
                                :rules="toValidate(isParent.mrValidation.aukprj_penglihatan_buram)"
                            />
                            -->
                        </td>
                        </tr>
                        <tr>
                        <td>Apakah pasien mempunyai glaucoma, katarak atau degenerasi makula?</td>
                        <td>
                            <b-form-radio-group
                                
                                :options="isParent.Config.mr.yesNoOptV2"
                                v-model="isParent.row.aukprj_penglihatan_glaucoma"
                            />
                            <!--
                            <VValidate 
                                name="Penglihatan Glaucomma" 
                                v-model="isParent.row.aukprj_penglihatan_glaucoma" 
                                :rules="toValidate(isParent.mrValidation.aukprj_penglihatan_glaucoma)"
                            />
                            -->
                        </td>
                        </tr>
                    </tbody>
                    <tbody>
                        <tr class="table-warning">
                        <th colspan="2"><span class="font-weight-semibold">Kebiasaan</span></th>
                        </tr>
                        <tr>
                        <td>Apakah terdapat perubahan perilaku berkemih? (frekuensi, urgensi, inkontinensia, nokturia)</td>
                        <td>
                            <b-form-radio-group
                                
                                :options="isParent.Config.mr.yesNoOptV2"
                                v-model="isParent.row.aukprj_kebiasaan"
                            />
                            <!--
                            <VValidate 
                                name="Perilaku Berkemih" 
                                v-model="isParent.row.aukprj_kebiasaan" 
                                :rules="toValidate(isParent.mrValidation.aukprj_kebiasaan)"
                            />
                            -->
                        </td>
                        </tr>

                        
                    </tbody>
                    <tbody>
                        <tr class="table-warning">
                        <th colspan="2"><span class="font-weight-semibold">Transfer (dari tempat tidur ke kursi dan kembali ke tempat tidur)</span></th>
                        </tr>
                        <tr v-for="(v,k) in (Config.mr.syTransfer||[])" :key="k">
                            <td colspan="2">
                                <b-form-radio v-model="isParent.row.aukprj_transfer" name="aukprj_transfer" :value="v.value">{{v.text}}</b-form-radio>
                            </td>
                        </tr>
                    </tbody>
                    <tbody>
                        <tr class="table-warning">
                        <th colspan="2"><span class="font-weight-semibold">Mobilitas</span></th>
                        </tr>
                        <tr v-for="(v,k) in (Config.mr.syMobilitas||[])" :key="k">
                            <td colspan="2">
                                <b-form-radio v-model="isParent.row.aukprj_mobilitas" name="aukprj_mobilitas" :value="v.value">{{v.text}}</b-form-radio>
                            </td>
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr class="table-info">
                        <td colspan="5" class="text-info-700">
                            <span class="text-uppercase">Tingkat Risiko Ontario Modified Stratify - Sydney Scoring: {{getTotalSydney}}</span>

                            <span v-if="getTotalSydney < 6" class="border-left ml-2 pl-2 font-weight-semibold">Risiko Rendah</span>
                            <span v-else-if="getTotalSydney >= 6 && getTotalSydney < 17" class="border-left ml-2 pl-2 font-weight-semibold">Risiko Sedang</span>
                            <span v-else class="border-left ml-2 pl-2 font-weight-semibold">Risiko Tinggi</span>

                        </td>
                        <!-- Penilaian Tingkat Risiko Ontario Modified Stratify - Sydney Scoring
                        0 - 5 = Risiko Rendah
                        6 - 16 = Risiko Sedang
                        17 - 30 = Risiko Tinggi
                        -->
                        </tr>
                    </tfoot>
                    </table>
                    <!--
                    <VValidate 
                        name="Transfer" 
                        v-model="isParent.row.aukprj_transfer" 
                        :rules="toValidate(isParent.mrValidation.aukprj_transfer)"
                        class="d-block"
                    />
                    
                    <VValidate 
                        name="Mobilitas" 
                        v-model="isParent.row.aukprj_mobilitas" 
                        :rules="toValidate(isParent.mrValidation.aukprj_mobilitas)"
                        class="d-block"
                    />
                    -->
                
                    

                </div>
                </div>
            </div>
        </div>
        
        <div class="card" v-if="showIntervensi || (isParent.row.aukprj_resiko_type && (edTotal >= 90 || hdTotal >= 12 || morsheTotal >= 25 || getTotalSydney >=6))">
            <div class="card-header bg-info">
            <h5 class="card-title font-weight-semibold">Intervensi Risiko Jatuh</h5>
            </div>
            <div class="card-body">
            <div>
                <table class="table table-bordered table-sm table-striped">
                <thead>
                    <tr>
                    <th width="50%">Tindakan</th>
                    <th width="50%">Evaluasi</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                    <td>
                        <div class="custom-control custom-control-inline custom-checkbox">
                        <b-form-checkbox v-model="isParent.row.aukprj_memasang_gelang" value="Y"
                        unchecked-value="N" class="form-check-input-styled p-0" name="gelang">Memasang gelang kuning risiko jatuh
                        </b-form-checkbox>
                        </div>
                        </td><td>
                        <div class="form-inline">
                            <label>Terpasang gelang warna kuning pada</label>
                            <b-form-input v-model="isParent.row.aukprj_memasang_gelang_text" type="text" class="form-control form-control-sm mx-2" />
                            <label>pasien</label>
                        </div>
                                                      
                        <VValidate 
                            name="Memasang Gelang Kuning" 
                            v-if="isParent.row.aukprj_memasang_gelang == 'Y'"
                            v-model="isParent.row.aukprj_memasang_gelang_text" 
                            :rules="{required: 1}"
                        />

                        </td>
                    
                    </tr>
                    <tr>
                    <td>
                        <div class="custom-control custom-control-inline custom-checkbox">
                        <b-form-checkbox v-model="isParent.row.aukprj_memasang_pengaman" value="Y"
                        unchecked-value="N" class="form-check-input-styled p-0" name="pengaman">Memasang pagar pengaman tempat tidur
                        </b-form-checkbox>
                        </div>
                        </td><td><span>Pagar pengaman tempat tidur terpasang pada kedua sisi</span></td>
                    
                    </tr>
                    <tr>
                    <td>
                        <div class="custom-control custom-control-inline custom-checkbox">
                        <b-form-checkbox v-model="isParent.row.aukprj_memasang_roda" value="Y"
                        unchecked-value="N" class="form-check-input-styled p-0" name="roda">Mengunci roda tempat tidur
                        </b-form-checkbox>
                        </div>
                        </td><td><span>Roda tempat tidur terkunci dan tidak mudah tergeser</span></td>
                    
                    </tr>
                    <tr>
                    <td>
                        <div class="custom-control custom-control-inline custom-checkbox">
                        <b-form-checkbox v-model="isParent.row.aukprj_memasang_restrain" value="Y"
                        unchecked-value="N" class="form-check-input-styled p-0" name="restrain">Memasang restrain
                        </b-form-checkbox>
                        </div>
                        </td><td>
                        <div class="form-inline">
                            <label>Terpasang restrain di</label>
                            <b-form-input v-model="isParent.row.aukprj_memasang_restrain_text" type="text" class="form-control form-control-sm mx-2" />
                            <label>pasien, restrain menggunakan kain</label>
                        </div>
                  
                        <VValidate 
                            name="Terpasang restrain di" 
                            v-if="isParent.row.aukprj_memasang_restrain == 'Y'"
                            v-model="isParent.row.aukprj_memasang_restrain_text" 
                            :rules="{required: 1}"
                        />
                        </td>
                    
                    </tr>
                    <tr>
                    <td>
                        <div class="custom-control custom-control-inline custom-checkbox">
                        <b-form-checkbox v-model="isParent.row.aukprj_memasang_edukasi" value="Y"
                        unchecked-value="N" class="form-check-input-styled p-0" name="edukasi">Memberikan edukasi pencegahan jatuh pada pasien dan keluarga
                        </b-form-checkbox>
                        </div>
                        </td><td><span>Edukasi tentang pasien dipastikan selalu ada yang menunggu</span></td>
                    
                    </tr>
                </tbody>
                </table>
            </div>
            </div>
        </div>
    </div>
</template>


<script>
import $ from 'jquery'
const _ = global._

import Gen from '@/libs/Gen.js'
import GlobalVue from '@/libs/Global.vue'
const moment = require('moment')

export default{
    extends: GlobalVue,
    methods: {
        toValidate(val){
            return {...val}
        },
    },
    data(){
        return {
            mGangguanJiwa: [
                {
                    text: 'Kajian Humpty Dumpty',
                    value: '1' 
                },
                {
                    text: 'Kajian Morse Fall Scale',
                    value: '2' 
                },
                {
                    text: 'Kajian Skala Ontario Modified Stratify - Sydney Scoring',
                    value: '3' 
                },
            ]
        }
    },
    
    computed: {
        showIntervensi(){
            if(this.isParent.row.aukprj_is_gangguan_jiwa == 'Y'){
                return this.edTotal >= 90
            }
            else{
                if(this.isParent.row.ap_usia <= 18){
                    return this.hdTotal >= 12
                }else if(this.isParent.row.ap_usia > 18 && this.isParent.row.ap_usia <= 60){
                    return this.morsheTotal >= 25
                }else if(this.isParent.row.ap_usia > 60){
                    return this.getTotalSydney >=6
                }else{
                    return false
                }
            }
        },
        
        isParent(){
            return this.$parent.$parent.$parent.$parent
        },

        edTotal(){
            return (this.isParent.row.aukprj_ed_umur||0) + 
            (this.isParent.row.aukprj_ed_status_mental||0) + 
            ((this.isParent.row.aukprj_ed_pola_pikir == 11 ? (11+1): this.isParent.row.aukprj_ed_pola_pikir)||0) + 
            (this.isParent.row.aukprj_ed_pengobatan||0) + 
            (this.isParent.row.aukprj_ed_diagnosis||0) + 
            (this.isParent.row.aukprj_ed_ambulasi||0) + 
            (this.isParent.row.aukprj_ed_nutrisi||0) + 
            (this.isParent.row.aukprj_ed_riwayat_jatuh||0) 
        },

        hdTotal(){
            return (this.isParent.row.aukprj_humpty_umur||0) + 
            (this.isParent.row.aukprj_humpty_jenis_kelamin||0) + 
            (this.isParent.row.aukprj_humpty_diagnosis||0) + 
            (this.isParent.row.aukprj_humpty_gangguan_kognitif||0) + 
            (this.isParent.row.aukprj_humpty_faktor_lingkungan||0) + 
            (this.isParent.row.aukprj_humpty_respon_pembedahan||0) +
            (this.isParent.row.aukprj_humpty_respon_penggunaan_obat||0) 
        },

        morsheTotal(){
            return (this.isParent.row.aukprj_morshe_riwayat_jatuh||0) + 
            (this.isParent.row.aukprj_morshe_diagnosa||0) + 
            (this.isParent.row.aukprj_morshe_alat_bantu||0) + 
            (this.isParent.row.aukprj_morshe_inpus||0) + 
            (this.isParent.row.aukprj_morshe_cara_jalan||0) + 
            (this.isParent.row.aukprj_morshe_status_mental||0) 
        },

        
        getTotalSydney(){
            let total = 0
            
            if(this.isParent.row.aukprj_riwayat_jatuh_is_jatuh == "Y" || this.isParent.row.aukprj_riwayat_jatuh_is_jatuh2bulan == "Y"){
                total = total + 6
            }

            if(this.isParent.row.aukprj_status_mental_dellirium == "Y" || this.isParent.row.aukprj_status_mental_disorientasi == "Y" ||
            this.isParent.row.aukprj_status_mental_agitasi == "Y" ){
                total = total + 14
            }
            
            if(this.isParent.row.aukprj_penglihatan_kacamata == "Y" || this.isParent.row.aukprj_penglihatan_buram == "Y" ||
            this.isParent.row.aukprj_penglihatan_glaucoma == "Y" ){
                total = total + 1
            }

            if(this.isParent.row.aukprj_kebiasaan == "Y"){
                total = total + 2
            }

            if(this.isParent.row.aukprj_transfer + this.isParent.row.aukprj_mobilitas > 3){
                total = total + 7
            }
            return total  
        },
    
    },
    
    watch:{
        edTotal(v){
            setTimeout(()=>{
                this.isParent.row.aukprj_ed_value = v
            },250)
        },

        hdTotal(v){
            setTimeout(()=>{
                this.isParent.row.aukprj_humpty_value = v
            },250)
        },

        morsheTotal(v){
            setTimeout(()=>{
                this.isParent.row.aukprj_morshe_value = v
            },250)
        }
    }
}
</script>