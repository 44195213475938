<template>
    <div class="row g-2">
        <div class="col-md-6">
            <div>
            <label for="perKeluhan">Keluhan Utama<strong class="text-danger">*</strong></label>
            <b-form-input v-model="isParent.row.aukpa_keluhan_utama" @input="isParent.inputData('keluhan_utama')" id="keluhan_utama" :formatter="normalText" type="text" name="perKeluhan" class="form-control" placeholder="Keluhan dari pasien" />
            </div>
            <small class="text-info">*Mohon tuliskan informasi waktu mulai keluhan terjadi</small><br/>

            <span class="text-warning d-none" id="keluhan_utama-typing">User Lain Sedang Mengisi ...</span>            

            <VValidate 
                name="Keluhan Utama" 
                v-model="isParent.row.aukpa_keluhan_utama" 
                :rules="toValidate(isParent.mrValidation.aukpa_keluhan_utama)"
            />
        </div>
        <div class="col-md-6">
            <div>
            <label for="perRiwayat">Riwayat Penyakit Sekarang</label>
            <b-form-textarea @input="isParent.inputData('riwayat')" id="riwayat" v-model="isParent.row.aukpa_riwayat_penyakit" :formatter="normalText"  name="perRiwayat" rows="2" class="form-control" placeholder="Riwayat penyakit dari pasien"></b-form-textarea>
            </div>

            <span class="text-warning d-none" id="riwayat-typing">User Lain Sedang Mengisi ...</span>            

            <VValidate 
                name="Riwayat Penyakit Sekarang" 
                v-model="isParent.row.aukpa_riwayat_penyakit" 
                :rules="toValidate(isParent.mrValidation.aukpa_riwayat_penyakit)"
            />
        </div>
        <div class="col-md-6">
            <div>
            <label for="perRiwayat">Riwayat Pengobatan</label>
            <b-form-textarea  v-model="isParent.row.aukpa_riwayat_pengobatan" :formatter="normalText"  name="perRiwayat" id="perRiwayat" rows="2" class="form-control" placeholder="Riwayat Pengobatan dari pasien"></b-form-textarea>
            </div>
            <VValidate 
                name="Riwayat Pengobatan" 
                v-model="isParent.row.aukpa_riwayat_pengobatan" 
                :rules="toValidate(isParent.mrValidation.aukpa_riwayat_pengobatan)"
            />
            
        </div>
        
        <div class="col-md-6">
        <div class="form-group">
            <label>Riwayat Penyakit Terdahulu</label>
            <textarea @input="isParent.inputData('riwayat-old')" id="riwayat-old" v-model="isParent.row.aukpa_riwayat_penyakit_old" rows="2" class="form-control" placeholder="" spellcheck="false"></textarea>

            <span class="text-warning d-none" id="riwayat-old-typing">User Lain Sedang Mengisi ...</span>            
        </div>
        </div>

        <div class="col-md-6">
            <div>
            <label for="perRiwayat">Last Oral Intake</label>
            <b-form-textarea  v-model="isParent.row.aukpa_last_oral_intake" :formatter="normalText"  name="perRiwayat" id="perRiwayat" rows="2" class="form-control" placeholder="Last Oral Intake dari pasien"></b-form-textarea>
            </div>
            <VValidate 
                name="Last Oral Intake" 
                v-model="isParent.row.aukpa_last_oral_intake" 
                :rules="toValidate(isParent.mrValidation.aukpa_last_oral_intake)"
            />
        </div>
        <div class="col-12">
            <div class="row">
            <div class="col-md-7">
                <div class="head_panel_red">
                <div class="d-flex justify-content-between align-items-center">
                    <h3>ALERGI </h3>
                    <div>
                    <b-form-radio-group
                        id="has_alergi"
                        @change="isParent.inputData('has_alergi')"
                        @input="changeAlergi($event)"
                        :options="isParent.Config.mr.yesNoOpt"
                        v-model="isParent.row.aukpa_has_alergi"
                    />

                    <span class="text-warning d-none" id="has_alergi-typing">User Lain Sedang Mengisi ...</span>            

                    <VValidate 
                        name="Alergi" 
                        v-model="isParent.row.aukpa_has_alergi" 
                        :rules="toValidate(isParent.mrValidation.aukpa_has_alergi)"
                    />
                    </div>
                </div>
                </div>
                
                <template v-if="isParent.row.aukpa_has_alergi == 'Y'">
                    <table class="table table-sm table-bordered">
                        <thead>
                          <tr>
                            <th width="33%">Jenis</th>
                            <th>Informasi Alergi</th>
                            <th width="64"></th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="(v,k) in (isParent.row.aukpa_alergi||[])" :key="k+'aler'">
                            <td>
                                <v-select placeholder="Pilih Jenis" v-model="isParent.row.aukpa_alergi[k]['jenis']" :options="Config.mr.jenisAlergi" @input="isParent.inputData('alergi')" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                                <VValidate 
                                    :name="'Obat '+(k+1)" 
                                    v-model="isParent.row.aukpa_alergi[k]['jenis']" 
                                    :rules="{required:1}"
                                />
                            </td>
                            <td>
                                <vue-typeahead-bootstrap
                                v-model="isParent.row.aukpa_alergi[k]['name']"
                                :data="isParent.mAlergi" @input="searchAlergi(isParent.row.aukpa_alergi[k]['name']);isParent.inputData('alergi')"
                                placeholder="Pilih Alergi"
                                />
                                
                                <VValidate 
                                    :name="'Obat '+(k+1)" 
                                    v-model="isParent.row.aukpa_alergi[k]['name']" 
                                    :rules="{required:1}"
                                />
                            </td>
                            <td>
                              <a href="javascript:;" @click="removeAlergi(k)" data-popup="tooltip" title="Hapus" class="btn btn-sm btn-icon border-danger rounded-round text-danger-800 alpha-danger"><i class="icon-trash"></i></a>
                            </td>
                          </tr>
                        </tbody>
                        <tfoot>
                          <tr>
                            <td colspan="3" class="text-center">
                              <a href="javascript:;" @click="addAlergi()" class="btn btn-sm alpha-info border-info"><i class="icon-plus2 mr-1"></i>Tambah</a>
                            </td>
                          </tr>
                        </tfoot>
                    </table>
                    <span class="text-warning d-none" id="alergi-typing">User Lain Sedang Mengisi ...</span>            
                </template>
                <template v-else>
                    <table class="table table-sm table-bordered">
                        <thead>
                          <tr>
                            <th width="33%">Jenis</th>
                            <th>Informasi Alergi</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                                <v-select placeholder="Pilih Jenis" disabled :options="Config.mr.jenisAlergi" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                            </td>
                            <td>
                              <input disabled type="text" class="form-control" placeholder="cth. amoxicilin">
                            </td>
                          </tr>
                        </tbody>
                    </table>
                </template>
            </div>


            <div class="col-md-5">
                <div class="head_panel_blue">
                <div class="d-flex justify-content-between align-items-center">
                    <h3>OBAT RUTIN </h3>
                    <div>
                    <b-form-radio-group
                        id="has_obat_rutin"
                        @change="isParent.inputData('has_obat_rutin')"
                        :options="isParent.Config.mr.yesNoOpt"
                        v-model="isParent.row.aukpa_has_obat_rutin"
                    />

                    <span class="text-warning d-none" id="has_obat_rutin-typing">User Lain Sedang Mengisi ...</span>            
                    <VValidate 
                        name="Obat Rutin" 
                        v-model="isParent.row.aukpa_has_obat_rutin" 
                        :rules="toValidate(isParent.mrValidation.aukpa_has_obat_rutin)"
                    />
                    </div>
                </div>
                </div>

                <div class="row mt-2" v-if="isParent.row.aukpa_has_obat_rutin == 'Y'">
                <div class="col-md-12">
                    <div class="form-group">
                    <label>Keterangan </label>
                    <b-form-textarea id="keterangan_obat_rutin" @input="isParent.inputData('keterangan_obat_rutin')" v-model="isParent.row.aukpa_keterangan_obat_rutin" rows="5" cols="3" class="form-control" placeholder=""
                        spellcheck="false"></b-form-textarea>
                    </div>

                    <span class="text-warning d-none" id="keterangan_obat_rutin-typing">User Lain Sedang Mengisi ...</span>          
                    
                    <VValidate 
                        name="Keterangan" 
                        v-model="isParent.row.aukpa_keterangan_obat_rutin" 
                        :rules="toValidate(isParent.mrValidation.aukpa_keterangan_obat_rutin)"
                    />
                </div>
                </div>
            </div>
            </div>
        </div>
        <div class="col-12">
            <div class="card mb-0">
                <div class="card-header bg_head_panel">
                    <h6 class="card-title font-weight-semibold">Tanda Tanda Vital</h6>
                </div>
                <div class="card-body p-3">
                    <div class="row">
                    <div class="col-md-4">
                        <div class="form-group">
                        <label>Tekanan Darah</label>
                        <div class="input-group">
                            <b-form-input id="tekanan_darah_min" @input="isParent.inputData('tekanan_darah_min')"
                            :formatter="number" v-model="isParent.row.aukpa_ttv_tekanan_darah_min" type="text" name="name" class="form-control" placeholder="Systole"/>
                            
                            <div class="input-group-append input-group-prepend"><span class="input-group-text">/</span></div>

                            <b-form-input id="tekanan_darah_max" @input="isParent.inputData('tekanan_darah_max')" :formatter="number" placeholder="Diastole" v-model="isParent.row.aukpa_ttv_tekanan_darah_max" type="text" class="form-control" />
                            <div class="input-group-append"><span class="input-group-text">mmHG</span></div>
                        </div>
                        
                        <span class="text-warning d-none" id="tekanan_darah_min-typing">User Lain Sedang Mengisi ...</span>    
                        
                        <span class="text-warning d-none" id="tekanan_darah_max-typing">User Lain Sedang Mengisi ...</span>             

                        <VValidate 
                            name="Tekanan Darah Min" 
                            v-model="isParent.row.aukpa_ttv_tekanan_darah_min" 
                            :rules="toValidate(isParent.mrValidation.aukpa_ttv_tekanan_darah_min)"
                        />
                        <VValidate 
                            name="Tekanan Darah Max" 
                            v-model="isParent.row.aukpa_ttv_tekanan_darah_max" 
                            :rules="toValidate(isParent.mrValidation.aukpa_ttv_tekanan_darah_max)"
                        />
                        </div>
                    </div>

                    <div class="col-md-5">
                        <div class="form-group">
                            <label>Nadi <span class="text-danger">*</span></label>
                            <div class="form-row">
                                <div class="col-md-12">
                                <div class="input-group">
                                    <b-form-input id="nadi" @input="isParent.inputData('nadi')" :formatter="number" v-model="isParent.row.aukpa_ttv_nadi" type="text" class="form-control" />
                                    <div class="input-group-append"><span class="input-group-text">x/mnt</span></div>
                                    <div class="input-group-append">
                                        <div style="width: 140px;">
                                        <v-select id="label" @input="isParent.inputData('label')" placeholder="Pilih Label" v-model="isParent.row.aukpa_ttv_label" :options="Config.mr.StatusRegular" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                                        </div>
                                    </div>
                                </div>
                                </div>
                            </div>

                            <span class="text-warning d-none" id="nadi-typing">User Lain Sedang Mengisi ...</span>    
                        
                            <span class="text-warning d-none" id="label-typing">User Lain Sedang Mengisi ...</span>             

                            <VValidate 
                                name="Nadi" 
                                v-model="isParent.row.aukpa_ttv_nadi" 
                                :rules="toValidate(isParent.mrValidation.aukpa_ttv_nadi)"
                            />
                            <VValidate 
                                name="Label" 
                                v-model="isParent.row.aukpa_ttv_label" 
                                :rules="toValidate(isParent.mrValidation.aukpa_ttv_label)"
                            />
                        </div>
                    </div>
                    
                    <div class="col-md-3">
                        <div class="form-group">
                        <label>Gula Darah</label>
                        <div class="form-row">
                            <div class="col-md-12">
                            <div class="input-group">
                                <b-form-input id="gula_darah" @input="isParent.inputData('gula_darah')" :formatter="alphanum" v-model="isParent.row.aukpa_ttv_gula_darah"  type="text" class="form-control" />
                                <div class="input-group-append"><span class="input-group-text">mg/dL</span>
                                </div>
                            </div>
                            </div>
                        </div>

                        <span class="text-warning d-none" id="gula_darah-typing">User Lain Sedang Mengisi ...</span>            
                        
                        <VValidate 
                            name="Gula Darah" 
                            v-model="isParent.row.aukpa_ttv_gula_darah" 
                            :rules="toValidate(isParent.mrValidation.aukpa_ttv_gula_darah)"
                        />
                        </div>
                    </div>

                    <div class="col-md-4">
                        <div class="form-group">
                            <label>Pernafasan<span class="text-danger">*</span></label>
                            <div class="input-group">
                                <b-form-input id="pernafasan" @input="isParent.inputData('pernafasan')"  :formatter="number" v-model="isParent.row.aukpa_ttv_pernafasan"  type="text" class="form-control" />
                                <div class="input-group-append"><span class="input-group-text">x/mnt</span></div>
                            </div>

                            <span class="text-warning d-none" id="pernafasan-typing">User Lain Sedang Mengisi ...</span>                                    
                            <VValidate 
                                name="Pernafasan" 
                                v-model="isParent.row.aukpa_ttv_pernafasan" 
                                :rules="toValidate(isParent.mrValidation.aukpa_ttv_pernafasan)"
                            />
                        </div>
                    </div>

                    <div class="col-md-3">
                        <div class="form-group">
                        <label>SPO2</label>
                        <div class="form-row">
                            <div class="col-md-12">
                            <div class="input-group">
                                <b-form-input id="spo2" @input="isParent.inputData('spo2')"  :formatter="number" v-model="isParent.row.aukpa_ttv_spo2"  type="text" class="form-control" />
                                <div class="input-group-append"><span class="input-group-text">%</span>
                                </div>
                            </div>
                            </div>
                        </div>
                        
                        <span class="text-warning d-none" id="spo2-typing">User Lain Sedang Mengisi ...</span>                                                            
                        <VValidate 
                            name="SPO2" 
                            v-model="isParent.row.aukpa_ttv_spo2" 
                            :rules="toValidate(isParent.mrValidation.aukpa_ttv_spo2)"
                        />
                        </div>
                    </div>

                    <div class="col-md-2">
                        <div class="form-group">
                        <label>Suhu<span class="text-danger">*</span></label>
                        <div class="form-row">

                            <div class="col-md-12">
                            <div class="input-group">
                                <b-form-input id="suhu" @input="isParent.inputData('suhu')" :formatter="number" v-model="isParent.row.aukpa_ttv_suhu"  type="text" class="form-control" />
                                <div class="input-group-append"><span class="input-group-text">C</span>
                                </div>
                            </div>
                            </div>
                        </div>

                        <span class="text-warning d-none" id="suhu-typing">User Lain Sedang Mengisi ...</span>                                                                                    
                        <VValidate 
                            name="Suhu" 
                            v-model="isParent.row.aukpa_ttv_suhu" 
                            :rules="toValidate(isParent.mrValidation.aukpa_ttv_suhu)"
                        />
                        </div>
                    </div>

                    <div class="col-md-2">
                        <div class="form-group">
                        <label>Berat Badan</label>
                        <div class="form-row">

                            <div class="col-md-12">
                            <div class="input-group">
                                <b-form-input id="weight" @input="hitungBMI();isParent.inputData('weight')" :formatter="number" v-model="isParent.row.aukpa_ttv_weight"  type="text" class="form-control" />
                                <div class="input-group-append"><span class="input-group-text">kg</span>
                                </div>
                            </div>
                            </div>
                        </div>

                        <span class="text-warning d-none" id="weight-typing">User Lain Sedang Mengisi ...</span>                                                                                    
                        <VValidate 
                            name="Berat Badan" 
                            v-model="isParent.row.aukpa_ttv_weight" 
                            :rules="toValidate(isParent.mrValidation.aukpa_ttv_weight)"
                        />
                        </div>
                    </div>

                    
                    <div class="col-md-2">
                        <div class="form-group">
                        <label>Tinggi</label>
                        <div class="form-row">

                            <div class="col-md-12">
                            <div class="input-group">
                                <b-form-input id="height" @input="hitungBMI();isParent.inputData('height')"  :formatter="number" v-model="isParent.row.aukpa_ttv_height"  type="text" class="form-control" />
                                <div class="input-group-append"><span class="input-group-text">cm</span>
                                </div>
                            </div>
                            </div>
                        </div>

                        <span class="text-warning d-none" id="height-typing">User Lain Sedang Mengisi ...</span>                                                                                                            
                        <VValidate 
                            name="Tinggi Badan" 
                            v-model="isParent.row.aukpa_ttv_height" 
                            :rules="toValidate(isParent.mrValidation.aukpa_ttv_height)"
                        />
                        </div>
                    </div>

                    <div class="col-md-3">
                        <div class="form-group">
                        <label>Lingkar Kepala</label>
                        <div class="form-row">

                            <div class="col-md-12">
                            <div class="input-group">
                                <b-form-input id="lingkar_kepala" @input="isParent.inputData('lingkar_kepala')" :formatter="number" v-model="isParent.row.aukpa_ttv_lingkar_kepala"  type="text" class="form-control" />
                                <div class="input-group-append"><span class="input-group-text">cm</span>
                                </div>
                            </div>
                            </div>
                        </div>

                        <span class="text-warning d-none" id="lingkar_kepala-typing">User Lain Sedang Mengisi ...</span>  

                        <VValidate 
                            name="Lingkar Kepala" 
                            v-model="isParent.row.aukpa_ttv_lingkar_kepala" 
                            :rules="toValidate(isParent.mrValidation.aukpa_ttv_lingkar_kepala)"
                        />
                        </div>
                    </div>

                    <div class="col-md-3">
                        <div class="form-group">
                        <label>BMI</label>
                        <div class="form-row">
                            <div class="col-md-12">
                            <div class="input-group">
                                <b-form-input disabled v-model="isParent.row.aukpa_ttv_bmi"  type="text" class="form-control" />
                                <div class="input-group-append"><span class="input-group-text">m2</span>
                                </div>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>

                    <div class="col-md-3" v-if="isParent.row.ap_usia <= 15">
                        <div class="form-group">
                        <label>Luas Permukaan Tubuh Anak</label>
                        <div class="input-group">
                            <b-form-input id="permukaan_anak" @input="isParent.inputData('permukaan_anak')" :formatter="number" v-model="isParent.row.aukpa_ttv_luas_permukaan_anak"  type="text" class="form-control" />
                            <div class="input-group-append"><span class="input-group-text">m<sup>2</sup></span></div>
                        </div>
                        </div>
                        
                        <span class="text-warning d-none" id="permukaan_anak-typing">User Lain Sedang Mengisi ...</span>  
                    </div>

                    <!--
                    <div class="col-md-3">
                        <div class="form-group">
                        <label>Kesadaran</label>
                        <div class="form-row">
                            <div class="col-md-12">
                                <v-select placeholder="Pilih Kesadaran" v-model="isParent.row.aukpa_ttv_kesadaran" :options="isParent.mKesadaran" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                            </div>
                        </div>
                        <VValidate 
                            name="Kesadaran" 
                            v-model="isParent.row.aukpa_ttv_kesadaran" 
                            :rules="toValidate(isParent.mrValidation.aukpa_ttv_kesadaran)"
                        />
                        </div>
                    </div>
                    -->

                    <!--
                    <div class="col-md-3" v-if="!isParent.row.aukpa_ttv_bmi">
                        <div class="form-group">
                            <label>IMT</label>
                            <b-form-input v-model="isParent.row.aukpa_ttv_imt" type="text"
                            class="form-control" />
                        </div>
                    </div>
                    -->

                    </div>
                </div>
            </div>
        </div>
        <div class="col-12">
            <div class="card mb-0">
            <div class="card-header bg_head_panel">
                <h6 class="card-title font-weight-semibold">Risiko Luka Decubitus</h6>
            </div>
            <div class="card-body p-3">
                <table class="table table-bordered table-hover pain-scale-table table-sm">
                <thead>
                    <tr>
                    <th class="text-center" rowspan="2">Pengkajian</th>
                    <th class="text-center" colspan="4">Nilai</th>
                    </tr>
                    <tr>
                    <th class="text-center">4</th>
                    <th class="text-center">3</th>
                    <th class="text-center">2</th>
                    <th class="text-center">1</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                    <th class="table-warning">Kondisi Fisik</th>

                    <td v-for="(v,k) in (Config.mr.rldKondisiFisik||[])" :key="k">
                        <b-form-radio v-model="isParent.row.aukpa_risiko_luka_kondisi_fisik" name="kf" :value="v.value">{{v.text}}</b-form-radio>
                    </td>
                    
                    </tr>
                    <tr>
                    <th class="table-warning">Kesadaran</th>
                    <td v-for="(v,k) in (Config.mr.rldKesadaran||[])" :key="k">
                        <b-form-radio v-model="isParent.row.aukpa_risiko_luka_kesadaran" name="ks" :value="v.value">{{v.text}}</b-form-radio>
                    </td>
                    </tr>
                    <tr>
                    <th class="table-warning">Aktivitas</th>
                    <td v-for="(v,k) in (Config.mr.rldAktivitas||[])" :key="k">
                        <b-form-radio v-model="isParent.row.aukpa_risiko_luka_aktivitas" name="ka" :value="v.value">{{v.text}}</b-form-radio>
                    </td>
                    </tr>
                    <tr>
                    <th class="table-warning">Mobilitas</th>
                    <td v-for="(v,k) in (Config.mr.rldMobilitas||[])" :key="k">
                        <b-form-radio v-model="isParent.row.aukpa_risiko_luka_mobilitas" name="km" :value="v.value">{{v.text}}</b-form-radio>
                    </td>
                    </tr>
                    <tr>
                    <th class="table-warning">Inkontinensia</th>
                    <td v-for="(v,k) in (Config.mr.rldInkontinensia||[])" :key="k">
                        <b-form-radio v-model="isParent.row.aukpa_risiko_luka_inkontinensia" name="ink" :value="v.value">{{v.text}}</b-form-radio>
                    </td>
                    </tr>
                </tbody>
                <tfoot>
                    <tr class="table-info">
                    <td colspan="5" class="text-info-700">
                        <span>SKOR RISIKO: {{risikoDecTotal}}</span>
                        <span v-if="risikoDecTotal < 14" class="border-left ml-2 pl-2 font-weight-semibold">Risiko Decubitus Tinggi</span>
                        <span v-else class="border-left ml-2 pl-2 font-weight-semibold">Risiko Decubitus Rendah</span>
                    </td>
                    <!-- Penilaian Skor Risiko Luka Decubitus
                    >14 = Santai dan Nyaman
                    1-3 = Ketidaknyamanan Ringan
                    -->
                    </tr>
                </tfoot>
                </table>

                <VValidate 
                    name="Kondisi Fisik" 
                    v-model="isParent.row.aukpa_risiko_luka_kondisi_fisik" 
                    :rules="toValidate(isParent.mrValidation.aukpa_risiko_luka_kondisi_fisik)"
                    class="d-block"
                />

                
                <VValidate 
                    name="Kesadaran" 
                    v-model="isParent.row.aukpa_risiko_luka_kesadaran" 
                    :rules="toValidate(isParent.mrValidation.aukpa_risiko_luka_kesadaran)"
                    class="d-block"
                />
                
                <VValidate 
                    name="Aktivitas" 
                    v-model="isParent.row.aukpa_risiko_luka_aktivitas" 
                    :rules="toValidate(isParent.mrValidation.aukpa_risiko_luka_aktivitas)"
                    class="d-block"
                />
                
                <VValidate 
                    name="Mobilitas" 
                    v-model="isParent.row.aukpa_risiko_luka_mobilitas" 
                    :rules="toValidate(isParent.mrValidation.aukpa_risiko_luka_mobilitas)"
                    class="d-block"
                />
                
                <VValidate 
                    name="Inkontinensia" 
                    v-model="isParent.row.aukpa_risiko_luka_inkontinensia" 
                    :rules="toValidate(isParent.mrValidation.aukpa_risiko_luka_inkontinensia)"
                    class="d-block"
                />
            </div>
            </div>
        </div>
        <!-- Status Kehamilan, muncul hanya jika jenis kelamin adalah perempuan -->
        <div class="col-12" v-if="((isParent.row.ap_gender == 2 || isParent.row.ap_gender == 0) && isParent.row.ap_usia >= 12 && isParent.row.ap_usia <= 60) || (isParent.row.ap_gender == null || isParent.row.ap_gender == 3 || isParent.row.ap_gender == 4)">
            <div class="card mb-0">
            <div class="card-header bg_head_panel">
                <h6 class="card-title font-weight-semibold">Status Kehamilan</h6>
            </div>
            <div class="card-body p-3">
                <div class="row gx-6">
                <div class="col-md-6 col-lg-4 col-xl-3">
                    <div>
                    <label for="perHamil">Pasien Sedang Hamil?<strong class="text-danger">*</strong></label>
                    
                    <b-form-radio-group
                        
                        :options="isParent.Config.mr.yesNoOptV2"
                        v-model="isParent.row.aukpa_is_hamil"
                    />
                    <VValidate 
                        name="Pasien Hamil" 
                        v-model="isParent.row.aukpa_is_hamil" 
                        :rules="toValidate(isParent.mrValidation.aukpa_is_hamil)"
                    />

                    </div>
                    <div class="mt-3">
                    <label for="perMenyusui">Pasien Menyusui?<strong class="text-danger">*</strong></label>
                    <div>
                        <b-form-radio-group
                            
                            :options="isParent.Config.mr.yesNoOptV2"
                            v-model="isParent.row.aukpa_is_menyusui"
                        />
                        <VValidate 
                            name="Pasien Menyusui" 
                            v-model="isParent.row.aukpa_is_menyusui" 
                            :rules="toValidate(isParent.mrValidation.aukpa_is_menyusui)"
                        />
                    </div>
                    </div>
                </div>
              
                <div class="col-md border-left" v-if="isParent.row.aukpa_is_hamil == 'Y'">
                    <div class="row g-2 mb-2">
                        <div class="col-md-8">
                            <label for="perRiwayat">Tinggi Fundus Uteri</label>
                            <b-form-textarea  v-model="isParent.row.aukpa_tinggi_fungus" :formatter="normalText"  name="perRiwayat" id="perRiwayat" rows="2" class="form-control" placeholder="Tinggi Fundus Uteri"></b-form-textarea>
                        </div>
                    </div>
              
                    <div class="row g-2">
                    <div class="col-md-4">
                        <div>
                        <label for="perGravid">Gravid<strong class="text-danger">*</strong></label>
                        <b-form-input :formatter="number" v-model="isParent.row.aukpa_gravid"  type="text" class="form-control" />
                        <VValidate 
                            name="Gravid" 
                            v-model="isParent.row.aukpa_gravid" 
                            :rules="toValidate(isParent.mrValidation.aukpa_gravid)"
                        />
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div>
                        <label for="perPara">Para<strong class="text-danger">*</strong></label>
                        <b-form-input :formatter="number" v-model="isParent.row.aukpa_para"  type="text" class="form-control" />
                        <VValidate 
                            name="Para" 
                            v-model="isParent.row.aukpa_para" 
                            :rules="toValidate(isParent.mrValidation.aukpa_para)"
                        />
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div>
                        <label for="perAbortus">Abortus<strong class="text-danger">*</strong></label>
                        <b-form-input :formatter="number" v-model="isParent.row.aukpa_abortus"  type="text" class="form-control" />
                        <VValidate 
                            name="Para" 
                            v-model="isParent.row.aukpa_abortus" 
                            :rules="toValidate(isParent.mrValidation.aukpa_abortus)"
                        />
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div>
                            <label for="perHpht">HPHT</label>
                            <div class="input-group mb-3">
                                <datepicker input-class="form-control transparent" placeholder="Pilih Tanggal" @input="changeHPL" class="my-datepicker" calendar-class="my-datepicker_calendar" v-model="isParent.row.aukpa_hpht" >
                                </datepicker>
                                <div class="input-group-append calendar-group">
                                <span class="input-group-text" id="basic-addon2"><i class="icon-calendar"></i></span>
                                </div>
                            </div>  
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div>
                        <label for="perHpl">HPL</label>
                            <div class="input-group mb-3">
                                <datepicker input-class="form-control transparent" placeholder="Tanggal HPL" disabled class="my-datepicker" calendar-class="my-datepicker_calendar" v-model="isParent.row.aukpa_hpl" >
                                </datepicker>
                                <div class="input-group-append calendar-group">
                                <span class="input-group-text" id="basic-addon2"><i class="icon-calendar"></i></span>
                                </div>
                            </div>  
                        </div>
                        <VValidate 
                            name="HPL" 
                            v-model="isParent.row.aukpa_hpl" 
                            :rules="toValidate(isParent.mrValidation.aukpa_hpl)"
                        />
                    </div>
                    </div>
                </div>
                </div>
            </div>
            </div>
        </div>

        <div class="col-12">
            <div class="card mb-0">
            <div class="card-header bg_head_panel">
                <h6 class="card-title font-weight-semibold">Status Psikologi</h6>
            </div>
            <div class="card-body p-3">
                <div>
                    <label for="perHub">Status Psikologis Pasien<strong class="text-danger">*</strong></label>
                    <div>
                        <b-form-radio
                            v-for="v in isParent.Config.mr.psikologiPot"
                            inline
                            v-model="isParent.row.aukpa_status_psikologi"
                            :key="v.value"
                            :value="v.value"
                            @input="inputPsikolog($event)"
                        >{{ v.text }}</b-form-radio>
                        <div class="align-middle d-inline-block" v-if="isParent.row.aukpa_status_psikologi == 6">
                            <b-form-input :formatter="normalText" v-model="isParent.row.aukpa_status_psikologi_text"  type="text" class="form-control" placeholder="Isi psikologis pasien"/>
                            <VValidate 
                                name="Psikologi Pasien Lainnya" 
                                v-model="isParent.row.aukpa_status_psikologi_text" 
                                :rules="toValidate(isParent.mrValidation.aukpa_status_psikologi_text)"
                            />
                        </div>
                        
                        <VValidate 
                            name="Psikologi Pasien" 
                            v-model="isParent.row.aukpa_status_psikologi" 
                            :rules="toValidate(isParent.mrValidation.aukpa_status_psikologi)"
                        />
                    </div>
                </div>
            </div>
            </div>
        </div>
        <div class="col-12">
            <div class="card mb-0">
            <div class="card-header bg_head_panel">
                <h6 class="card-title font-weight-semibold">Status Sosial Ekonomi</h6>
            </div>
            <div class="card-body p-3">
                <div class="row">
                <div class="col-md-6 col-lg-4 mb-3 col-xl-3">
                    <div>
                        <label for="perHamil">Pendidikan Pasien<strong class="text-danger">*</strong></label>
                        <v-select placeholder="Pilih Pendidikan" v-model="isParent.row.aukpa_pendidikan" :options="isParent.mPendidikan" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                    </div>
                    
                    <VValidate 
                        name="Pendidikan Pasien" 
                        v-model="isParent.row.aukpa_pendidikan" 
                        :rules="{required: 1}"
                    />
                </div>
                
                <div class="col-md-6 col-lg-4 mb-3 mb-2">
                    <div>
                        <label for="perHamil">Pekerjaan Pasien<strong class="text-danger">*</strong></label>
                        <v-select placeholder="Pilih Pekerjaan" v-model="isParent.row.aukpa_pekerjaan" :options="isParent.mPekerjaan" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                    </div>
                    
                    <VValidate 
                        name="Pekerjaan Pasien" 
                        v-model="isParent.row.aukpa_pekerjaan" 
                        :rules="{required: 1}"
                    />
                </div>
                <div class="col-lg-4" v-if="isParent.row.aukpa_pekerjaan == 99999">
                    <label for="perHamil">Pekerjaan Lainnya<strong class="text-danger">*</strong></label>
                    <b-form-input :formatter="normalText" v-model="isParent.row.aukpa_pekerjaan_lainnya"  type="text" class="form-control" placeholder="Isi Pekerjaan Lainnya"/>
                    <VValidate 
                        name="Isi Pekerjaan Lainnya" 
                        v-model="isParent.row.aukpa_pekerjaan_lainnya" 
                        :rules="{required: 1, min: 2}"
                    />
                </div>

                <div class="col-md-6 col-lg-4 mb-3 col-xl-3">
                    <div>
                        <label for="perHamil">Penghasilan Pasien<strong class="text-danger">*</strong></label>
                        <v-select placeholder="Pilih Penghasilan" v-model="isParent.row.aukpa_penghasilan" :options="isParent.mPenghasilan" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                    </div>
                    
                    <VValidate 
                        name="Penghasilan Pasien" 
                        v-model="isParent.row.aukpa_penghasilan" 
                        :rules="{required: 1}"
                    />
                </div>
                <div class="col-md-6 col-lg-4 mb-3 col-xl-3">
                    <div>
                    <label for="perHub">Hubungan dengan Keluarga<strong class="text-danger">*</strong></label>
                    <div>
                        <b-form-radio-group
                            
                            :options="isParent.Config.mr.baikNoOpt"
                            v-model="isParent.row.aukpa_hubungan_keluarga"
                        />
                        <VValidate 
                            name="Hubungan dengan Keluarga" 
                            v-model="isParent.row.aukpa_hubungan_keluarga" 
                            :rules="toValidate(isParent.mrValidation.aukpa_hubungan_keluarga)"
                        />
                    </div>
                    </div>
                </div>
                </div>
            </div>
            </div>
        </div>
        <div class="col-12">
            <div class="card mb-0">
            <div class="card-header bg_head_panel">
                <h6 class="card-title font-weight-semibold">Spiritual</h6>
            </div>
            <div class="card-body p-3">
                <div class="row">
                <div class="col-md-3">
                    <div class="form-group">
                    <label for="perHamil">Agama Pasien<strong class="text-danger">*</strong></label>
                    <v-select placeholder="Pilih Agama" v-model="isParent.row.aukpa_agama" :options="isParent.mAgama" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                    <VValidate 
                        name="Agama Pasien" 
                        v-model="isParent.row.aukpa_agama" 
                        :rules="{required: 1}"
                    />
                    </div>
                </div>
                <div class="col-md-3" v-if="isParent.row.aukpa_agama  == 99999">
                    <div class="form-group">
                    <label for="perHamil">Agama Pasien Lainnya<strong class="text-danger">*</strong></label>
                    <b-form-input v-model="isParent.row.aukpa_agama_lainnya" :formatter="normalText" type="text" name="perKeluhan" class="form-control" placeholder="Agama Pasien Lainnya" />
                    <VValidate 
                        name="Agama Pasien Lainnya" 
                        v-model="isParent.row.aukpa_agama_lainnya" 
                        :rules="{required: 1}"
                    />
                    </div>
                </div>

                <div class="w-100"></div>
                <div class="col-md-5">
                    <div class="form-group">
                    <label for="spiKeyakinan">Keyakinan</label>
                    <b-form-textarea :formatter="normalText" v-model="isParent.row.aukpa_keyakinan" name="spiKeyakinan" id="spiKeyakinan" rows="3" class="form-control"></b-form-textarea>
                    </div>
                </div>
                <div class="col-md-5">
                    <div class="form-group">
                    <label for="spiNilai">Nilai-nilai Pasien atau Keluarga</label>
                    <b-form-textarea :formatter="normalText" v-model="isParent.row.aukpa_nilai_nilai" name="spiNilai" id="spiNilai" rows="3" class="form-control"></b-form-textarea>
                    </div>
                </div>
                </div>
            </div>
            </div>
        </div>
        <div class="col-12">
            <div class="card">
            <div class="card-header bg_head_panel">
                <h6 class="card-title font-weight-semibold">Status Fungsional</h6>
            </div>
            <div class="card-body p-3">
                <div class="row gx-5">
                <div class="col-md-auto">
                    <div>
                    <label for="perHub">Alat Bantu<strong class="text-danger">*</strong></label>
                    <div>
                        <b-form-radio-group
                            
                            :options="Config.mr.yesNoOptV2"
                            v-model="isParent.row.aukpa_is_alat_bantu"
                        />

                        <v-select class="mt-3" v-if="isParent.row.aukpa_is_alat_bantu == 'Y'" placeholder="Pilih Alat Bantu" v-model="isParent.row.aukpa_alat_bantu" :options="isParent.mAlatBantu" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                        
                        <VValidate 
                            v-if="isParent.row.aukpa_is_alat_bantu == 'Y'"
                            name="Nama Alat Bantu" 
                            v-model="isParent.row.aukpa_alat_bantu" 
                            :rules="toValidate(isParent.mrValidation.aukpa_alat_bantu)"
                        /> 
                    </div> 
                    <VValidate 
                        name="Alat Bantu" 
                        v-model="isParent.row.aukpa_is_alat_bantu" 
                        :rules="toValidate(isParent.mrValidation.aukpa_is_alat_bantu)"
                    /> 
                    </div>
                </div>
                <div class="col-md-auto border-left">
                    <div>
                    <label for="perHub">ADL (Aktifitas Sehari-hari)<strong class="text-danger">*</strong></label>
                    <div>                    
                        <b-form-radio-group
                            
                            :options="Config.mr.adlStatus"
                            v-model="isParent.row.aukpa_adl"
                        />
                    </div>
                    </div>
                    
                    <VValidate 
                        name="Aktifitas Sehari-hari" 
                        v-model="isParent.row.aukpa_adl" 
                        :rules="toValidate(isParent.mrValidation.aukpa_adl)"
                    /> 
                </div>
                </div>
            </div>
            </div>
        </div>
        <div class="col-12">
            <div class="card mb-0">
            <div class="card-header bg_head_panel">
                <h5 class="card-title font-weight-semibold">Pengkajian Batuk</h5>
            </div>
            <div class="card-body p-3">
                <div class="form-group">
                <label for="">Pasien memiliki TB/COVID-19?</label>
                <div>
                    <b-form-radio-group
                        :options="isParent.Config.mr.yesNoOpt"
                        v-model="isParent.row.aukpa_is_batuk"
                    />
                    <VValidate 
                        name="Pasien memiliki TB/COVID-19" 
                        v-model="isParent.row.aukpa_is_batuk" 
                        :rules="toValidate(isParent.mrValidation.aukpa_is_batuk)"
                    />
                </div>
                </div>
                <table v-if="isParent.row.aukpa_is_batuk == 'Y'" class="table table-bordered">
                <thead>
                    <tr>
                    <th>Pengkajian</th>
                    <th>Nilai</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                    <td>Apakah pasien mengalami demam saat ini dan beberapa hari terakhir?</td>
                    <td>
                        <b-form-radio-group
                            :options="isParent.Config.mr.yesNoOpt"
                            v-model="isParent.row.aukpa_is_demam"
                        />
                        <VValidate 
                            name="Pasien Demam" 
                            v-model="isParent.row.aukpa_is_demam" 
                            :rules="toValidate(isParent.mrValidation.aukpa_is_demam)"
                        />
                    </td>
                    </tr>
                    <tr>
                    <td>Apakah pasien berkeringat pada malam hari tanpa beraktivitas?</td>
                    <td>
                        <b-form-radio-group
                            :options="isParent.Config.mr.yesNoOpt"
                            v-model="isParent.row.aukpa_is_keringat"
                        />
                        <VValidate 
                            name="Pasien Berkeringat" 
                            v-model="isParent.row.aukpa_is_keringat" 
                            :rules="toValidate(isParent.mrValidation.aukpa_is_keringat)"
                        />
                    </td>
                    </tr>
                    <tr>
                    <td>Apakah pasien memiliki riwayat bepergian dari daerah wabah?</td>
                    <td>
                        <b-form-radio-group
                            :options="isParent.Config.mr.yesNoOpt"
                            v-model="isParent.row.aukpa_is_wadah"
                        />
                        <VValidate 
                            name="Pasien memiliki wabah" 
                            v-model="isParent.row.aukpa_is_wadah" 
                            :rules="toValidate(isParent.mrValidation.aukpa_is_wadah)"
                        />
                    </td>
                    </tr>
                    <tr>
                    <td>Apakah pasien memiliki riwayat pemakaian obat jangka panjang?</td>
                    <td>
                        <b-form-radio-group
                            :options="isParent.Config.mr.yesNoOpt"
                            v-model="isParent.row.aukpa_is_jangka_panjang"
                        />
                        <VValidate 
                            name="Pasien memiliki pemakaian obat jangka panjang" 
                            v-model="isParent.row.aukpa_is_jangka_panjang" 
                            :rules="toValidate(isParent.mrValidation.aukpa_is_jangka_panjang)"
                        />
                    </td>
                    </tr>
                    <tr>
                    <td>Apakah pasien mengalami penurunan BB tanpa sebab yang diketahui?</td>
                    <td>
                        <b-form-radio-group
                            :options="isParent.Config.mr.yesNoOpt"
                            v-model="isParent.row.aukpa_is_bb"
                        />
                        <VValidate 
                            name="Pasien memiliki Penurunan Berat Badan" 
                            v-model="isParent.row.aukpa_is_bb" 
                            :rules="toValidate(isParent.mrValidation.aukpa_is_bb)"
                        />
                    </td>
                    </tr>
                </tbody>
                </table>
            </div>
            </div>
        </div>
    </div>
</template>


<script>
import $ from 'jquery'
const _ = global._

import Gen from '@/libs/Gen.js'
import GlobalVue from '@/libs/Global.vue'
import Datepicker from 'vuejs-datepicker'
const moment = require('moment')


export default{
    extends: GlobalVue,
    components:{ 
        Datepicker
    },
    
    methods: {
        toValidate(val){
            return {...val}
        },

        hitungBMI(){
            if(this.isParent.row.aukpa_ttv_weight && this.isParent.row.aukpa_ttv_height){
                this.isParent.row.aukpa_ttv_bmi = this.isParent.row.aukpa_ttv_weight 
                / ((this.isParent.row.aukpa_ttv_height/100)*(this.isParent.row.aukpa_ttv_height/100))
                this.isParent.row.aukpa_ttv_bmi = this.isParent.row.aukpa_ttv_bmi.toFixed(2) 
            }else{
                this.isParent.row.aukpa_ttv_bmi = null 
            }
        },
        
        changeHPL(){
            let dateHPL = moment(this.isParent.row.aukpa_hpht, "DD-MM-YYYY").format('YYYY-M-D')
            let splDate = dateHPL.split("-")
            let day = splDate[2]
            let month = splDate[1]
            let year = splDate[0]

            day = +day + 7
            if(month == 1){
                month = 10
            }else if(month == 2){
                month = 11            
            }else if(month == 3){
                month = 12
            }else{
                month = +month - 3
            }
            year = +year + 1
            
            let daysOfMonth = moment(+year+"-"+month+"-01").endOf("month").format("D")
            daysOfMonth = +daysOfMonth
            
            let newDay
            
            if(day > daysOfMonth){
                newDay = day - daysOfMonth
                month = month + 1
            }else{
                newDay = day
            }

            let newdateHPL = moment((newDay+'-'+month+'-'+year), "DD-MM-YYYY").format('YYYY-MM-DD')
          
            this.isParent.row.aukpa_hpl = newdateHPL
        },

        searchAlergi: _.debounce(function (e) {
          let data = {
              name: e,
              type: 'get-alergi'
          }
          Gen.apiRest(
              "/do/"+this.isParent.modulePage,
              {data:data}, 
              "POST"
          ).then(res=>{
              this.isParent.mAlergi = res.data.data            
          })
        }, 100),
        
        changeAlergi(e){
            if(!(this.isParent.row.aukpa_alergi||[]).length){
                this.addAlergi()
            }
        },
        
        addAlergi(){
            this.isParent.row.aukpa_alergi.push({
                name : '',
                jenis : null,
            })
            this.addMasterAlergi()
            this.isParent.inputData('alergi')
        },
        
        addMasterAlergi(){
            for(let i = 0; i < (this.isParent.row.aukpa_alergi||[]).length; i++){
                let newData = this.isParent.row.aukpa_alergi[i]
                if((newData.name||"").length && newData.jenis == "Obat"){
                    let dataPost = {
                        type : 'auto-save-riwayat',
                        name : newData.name
                    }  
                    Gen.apiRest(
                        "/do/"+this.isParent.modulePage,
                        {data:dataPost}, 
                        "POST"
                    )
                }
            }
        },
        
        removeAlergi(k){
            this.isParent.row.aukpa_alergi.splice(k,1)
            this.isParent.inputData('alergi')
        },
        
        inputPsikolog(e){
            if(this.isParent.row.aukpa_status_psikologi == 6){
                this.isParent.row.aukpa_status_psikologi_text = ''
            }
        },

    },
    computed: {
        isParent(){
            return this.$parent.$parent.$parent.$parent
        },
        risikoDecTotal(){
            return (this.isParent.row.aukpa_risiko_luka_kondisi_fisik||0) + 
            (this.isParent.row.aukpa_risiko_luka_kesadaran||0) + 
            (this.isParent.row.aukpa_risiko_luka_aktivitas||0) + 
            (this.isParent.row.aukpa_risiko_luka_mobilitas||0) + 
            (this.isParent.row.aukpa_risiko_luka_inkontinensia||0) 
        }
    }
}

</script>